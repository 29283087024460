@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');


.wrapper{
  background-color: #660098;
  width: 100%;
  height: 100%;
  position: fixed
}

.form_input{
  font-family: 'Quicksand', sans-serif;
  font-size: 13px;
}